import { useParams } from 'react-router-dom'

import { Box, Card, CardContent, Grid, Typography, styled } from '@mui/material'
import { DataGridPro, type GridColDef, type GridRowSpacingParams } from '@mui/x-data-grid-pro'
import { useQuery } from '@tanstack/react-query'

import {
  CardHeader,
  DataRow,
  Map,
  Page,
  Progress,
  Route,
  Tabs,
  runAnimation,
} from '@leaf/components'
import { useTabs } from '@leaf/hooks'
import type * as Types from '@leaf/types'
import { color, time } from '@leaf/utilities'

import {
  type AdaptNetworkMoveQueryReturnType,
  adaptNetworkMoveDetailsQuery,
} from '@/features/adapt-network-move/adapt-network-move-details.api'
import { AdaptNetworkMoveRouteScheduleLane } from '@/features/adapt-network-move/adapt-network-move-details-route-schedule-lane'
import { useTitles } from '@/hooks'

type LaneRowType = {
  id: string
} & Types.KeyValue

const Deadhead = styled(Box)`
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.38);
  margin-top: 4em;
`

const laneColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: '#',
    type: 'number',
  },
  {
    field: 'annualLoads',
    headerName: 'Annual Loads',
    type: 'number',
  },
  {
    field: 'leafMiles',
    headerName: 'Leaf Miles',
    type: 'number',
  },
  {
    field: 'companyMiles',
    headerName: 'Company Miles',
    type: 'number',
  },
  {
    field: 'deadhead',
    headerName: 'Deadhead Miles',
    type: 'number',
  },
  {
    field: 'loads',
    headerName: 'Loads (DRY/REEFER)',
    type: 'number',
  },
  {
    field: 'weeklyBase',
    headerName: 'Weekly Base',
  },
  {
    field: 'meanRpm',
    headerName: 'Mean RPM',
    type: 'number',
  },
  {
    field: 'outlierRpm',
    headerName: 'Outlier RPM',
    type: 'boolean',
  },
  {
    field: 'low',
    headerName: 'Low',
    type: 'boolean',
  },
  {
    field: 'balanceAdj',
    headerName: 'Balance Adjustment',
    type: 'number',
  },
  {
    field: 'oMarket',
    headerName: 'Origin Market',
  },
  {
    field: 'dMarket',
    headerName: 'Destination Market',
  },
  {
    field: 'equipmentClass',
    headerName: 'Equipment Class',
  },
  {
    field: 'networkType',
    headerName: 'Network Type',
  },
  {
    field: 'datout',
    headerName: 'DAT 90D LH/Out',
    type: 'number',
  },
  {
    field: 'datreturn',
    headerName: 'DAT 90D LH/Return',
    type: 'number',
  },
]

const AdaptNetworkMoveDetails = () => {
  const { id } = useParams()
  const { data } = useQuery(adaptNetworkMoveDetailsQuery(id!))
  const move = data as AdaptNetworkMoveQueryReturnType
  type MoveKey = keyof typeof move

  const { activeTab, setActiveTab } = useTabs()

  useTitles([{ to: 'adapt/network-moves', value: 'Adapt Network Moves' }, { value: id }])

  const laneRows: LaneRowType[] = []
  const shipperIds = []
  const fitBounds: Types.PositionalGeometry[] = []

  for (let i = 0; i < 5; i += 1) {
    const lane = move[`lane${i}` as MoveKey]
    if (!lane) {
      break
    }

    shipperIds.push(lane.company.id)
    fitBounds.push(lane.geometry)

    const deadhead = move[`lane${i + 1}` as MoveKey]
      ? move[`deadheadL${i}L${i + 1}` as MoveKey]
      : move.deadheadFinal

    laneRows.push({
      annualLoads: move[`l${i}AnnualLoads` as MoveKey] || '-',
      balanceAdj: move[`l${i}BalanceAdj` as MoveKey] || '-',
      companyMiles: lane.companyMiles || '-',
      dMarket: move[`l${i}DMarket` as MoveKey] || '-',
      datout: move[`l${i}Dat90DayLhOut` as MoveKey] || '-',
      datreturn: move[`l${i}Dat90DayLhReturn` as MoveKey] || '-',
      deadhead,
      equipmentClass: move[`l${i}EquipmentClass` as MoveKey] || '-',
      id: move[`lane${i}` as MoveKey].id,
      leafMiles: lane.leafMiles || '-',
      loads: `${move[`l${i}AnnualLoads` as MoveKey] || '0'} (${move[`l${i}DryLoadsAnnual` as MoveKey] || '0'}/${move[`l${i}ReeferLoadsAnnual` as MoveKey] || '0'})`,
      meanRpm: move[`l${i}MeanRpm` as MoveKey] || '-',
      networkType: move[`l${i}NetworkType` as MoveKey] || '-',
      oMarket: move[`l${i}OMarket` as MoveKey] || '-',
      outlierRpm: move[`l${i}OutlierRpm` as MoveKey],
      weeklyBase: move[`l${i}WeeklyBase` as MoveKey] || '-',
    })
  }

  const nShippers = new Set(shipperIds).size

  const laneLayerIds = laneRows.map((laneRow) => `lane-layer-${laneRow.id}-dash`)
  const onLoad = runAnimation(laneLayerIds)

  return (
    <>
      <Progress />

      <Page>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='General' />

            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <DataRow label='Network Type' value={move.networkType} />

                  <DataRow label='Move Type' value={move.moveType} />

                  <DataRow label='Batch Date' value={time.toLocalDate(move.batchDate)} />

                  <DataRow label='Balance Type' value={move.balanceType} />

                  <DataRow label='Balance Adjustment' value={move.balanceAdj} />

                  <DataRow label='Complexity' value={move.complexity} />

                  <DataRow label='Record Type' value={move.recordType} />
                </Grid>

                <Grid item xs={3}>
                  <DataRow label='Margin' value={move.margin} />

                  <DataRow label='Benefit' value={move.benefit} />

                  <DataRow label='Shippers' value={nShippers} />
                </Grid>

                <Grid item xs={3}>
                  <DataRow label='Total Miles' value={move.totalMiles} />

                  <DataRow label='Equipment Class' value={move.equipmentClass} />

                  <DataRow label='Origin Market' value={move.oMarket} />

                  <DataRow label='Destination Market' value={move.dMarket} />

                  <DataRow label='Days' value={move.days} />

                  <DataRow label='Low Legs' value={move.lowLegs} />
                </Grid>

                <Grid item xs={3}>
                  <DataRow label='Leaf Daily Linehaul LSP' value={move.leafDailyLinehaulLsp} />
                  <DataRow label='Mean Historical Linehaul LSP' value={move.meanHistLinehaulLsp} />
                  <DataRow
                    label='Shipper Trigger Linehaul LSP'
                    value={move.shipperTriggerLinehaulLsp}
                  />
                  <DataRow label='Supply Target Day Rate LSP' value={move.supplyTargetDayRateLsp} />
                  <DataRow
                    label='Supply Target Linehaul LSP'
                    value={move.supplyTargetLinehaulLsp}
                  />
                  <DataRow label='DAT 90D LH/Out' value={move.dat90DayLhOut} />
                  <DataRow label='DAT 90D LH/Return' value={move.dat90DayLhReturn} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <Tabs activeTab={activeTab} changeTab={setActiveTab} tabs={['Map', 'Lanes']} />

            <CardContent sx={{ padding: 0 }}>
              <Grid container>
                <Grid item sx={{ marginLeft: '3em', marginRight: '2em' }} xs={3}>
                  <Box sx={{ height: '100%', minWidth: '100%' }}>
                    {[0, 1, 2, 3, 4].map((i) => {
                      const lane = move[`lane${i}` as MoveKey]

                      if (!lane) {
                        return null
                      }
                      const deadhead = move[`lane${i + 1}` as MoveKey]
                        ? move[`deadheadL${i}L${i + 1}` as MoveKey]
                        : move.deadheadFinal

                      return (
                        <>
                          <AdaptNetworkMoveRouteScheduleLane
                            color={color.lane(i)}
                            data={lane}
                            position={i * 2 + 1}
                          />

                          <Deadhead>
                            {[null, undefined].includes(deadhead) || (
                              <Typography variant='caption'>{deadhead} miles</Typography>
                            )}
                          </Deadhead>
                        </>
                      )
                    })}
                  </Box>
                </Grid>

                <Grid item sx={{ width: '100vw' }} xs={8}>
                  {activeTab === 0 && (
                    <Box sx={{ height: 'calc(100% + 3em)', width: 'calc(100% + 3em)' }}>
                      <Map
                        fitBounds={fitBounds}
                        mapboxAccessToken={import.meta.env.VITE_MAPBOX_API_KEY}
                        onLoad={onLoad}
                      >
                        {laneRows.map((laneRow, i) => (
                          <Route.MapLane
                            animated
                            color={color.lane(i)}
                            geometry={fitBounds[i]}
                            id={laneRow.id}
                            position={i * 2}
                          />
                        ))}
                      </Map>
                    </Box>
                  )}

                  {activeTab === 1 && (
                    <DataGridPro
                      columns={laneColumns}
                      disableColumnMenu
                      disableColumnSorting
                      getRowSpacing={(params: GridRowSpacingParams) => {
                        return {
                          bottom: move[`lane${params.id}` as MoveKey] ? 160 : 0,
                          top: params.id === 1 ? 10 : 0,
                        }
                      }}
                      hideFooterRowCount
                      rowSelection={false}
                      rows={laneRows}
                      sx={{
                        '& .MuiDataGrid-row.Mui-hovered': {
                          backgroundColor: 'transparent',
                        },
                        '& .MuiDataGrid-row:hover': {
                          backgroundColor: 'transparent',
                        },
                        'width': 'calc(100% + 3em)',
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Page>
    </>
  )
}

export { AdaptNetworkMoveDetails }
